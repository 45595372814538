/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import '../App.css'
import "../styles/LockContainer.css";
import Input from "../components/Input.tsx";



const App = () => {
  const { isConnected } = useAccount();
  const { chain } = useNetwork();


  const { switchNetwork } = useSwitchNetwork()
  const [tid, setTid] = useState('');
  const [tdata, setTdata] = useState('');

  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(5)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain.id !== 5)
        switchChain();
    }
  }, [isConnected, chain?.id, switchNetwork])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTdata('')
        var url = `https://api.swapspace.co/api/v2/exchange/${tid}`;
        const options = {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "24a5f576a3ae0e0ddaf53582e750c3b1220763f9d5d41f257233c93297a36fffa7cafa08800f286f9a",
          },
        };
        fetch(url, options)
          .then(b => {
            let a = b.json();
            a.then(a => {
              let datas = [];
              datas.push(a.blockExplorerAddressUrl.from, a.blockExplorerAddressUrl.to, a.from.address, a.from.amount, a.from.network, a.id, a.partner, a.rate, a.refundAddress, a.status, a.timestamps.createdAt, a.timestamps.expiresAt, a.to.address, a.to.amount, a.to.network, a.to.transactionHash)
              setTdata(datas)
            })
          })
      } catch (e) {
        console.error(e)
      }
    }
    if (tid !== "") {
      fetchData();
    }
  }, [tid])

  return (
    <main>
      <div className="GlobalContainer">
        <div className="InputBox">
          <iframe src="https://swapspace.co/widget/07eecee5efcee1d9e5298331" frameborder="0" width="404px" height="536px" />
          <Input
            placeholder="Enter Swap ID..."
            label=""
            type="string"
            changeValue={setTid}
            value={tid}
          />
        </div>
        <div className="IconContainer">
          <div className='Content'>
            {tdata ?
              <>
                <p>from : {tdata[0]}</p>
                <p>to : {tdata[1]}</p>
                <p>Deposit Address : {tdata[2]}</p>
                <p>Deposit Amount : {tdata[3]}</p>
                <p>Deposit Network : {tdata[4]}</p>
                <p>Order Id : {tdata[5]}</p>
                <p>Partner : {tdata[6]}</p>
                <p>rate : {tdata[7]}</p>
                <p>Refund Address : {tdata[8]}</p>
                <p>Status : {tdata[9]}</p>
                <p>Created Time : {tdata[10].split("T")[0] + " " + tdata[10].split("T")[1]}</p>
                <p>Expired Time : {tdata[11].split("T")[0] + " " + tdata[11].split("T")[1]}</p>
                <p>Destination Address : {tdata[12]}</p>
                <p>Destination Amount : {tdata[13]}</p>
                <p>Destination Network : {tdata[14]}</p>
                <p>Transaction Hash : {tdata[15]}</p></>
              :
              <></>}


          </div>
        </div>
      </div>
    </main >
  )
}

export default App
